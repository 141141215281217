import styled from 'styled-components';

import { mediaQuery } from '../../../../../../styles/global';

export const WordsWrapper = styled.h1`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  color: ${({ theme }) => theme.colors.white};
  font-size: 4rem;
  font-weight: bold;
  text-align: start;
`;

export const WordsContainer = styled.span`
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  width: var(--width);
  height: 82px;
  padding: 0 8px;
  color: var(--color, #000);
  white-space: nowrap;
  box-sizing: content-box;
  transition: all 0.7s;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.1), 
               0 0 10px rgba(255, 255, 255, 0.1), 
               0 0 15px rgba(255, 255, 255, 0.1);

  span {
    transition: transform 0.7s, opacity 0.25s 0.25s;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    padding-left: 16px;

    &.current {
      opacity: 1;
      transform: translate(100%, 0); /* Slide in from the right */
    }

    &.next {
      transform: translate(150%, 0); /* Move out to the right */
    }
  }
`;

export const NewLine = styled.span`
  display: none;

  ${mediaQuery.maxWidth.tablet} {
    display: inline;
  }
`
