import React, { useEffect, useRef, useState } from 'react';

import * as S from './AnimatedMentalState.styles';

type Props = {
  variant: 'A' | 'B';
};

export const AnimatedMentalState = ({ variant }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const mentalStatesTestA = [
    { text: 'Focus', color: 'rgba(254, 90, 131, 1)' },
    { text: 'Sleep', color: 'rgba(189, 99, 244, 1)' },
    { text: 'Relaxation', color: 'rgba(105, 202, 255, 1)' },
    { text: 'Meditation', color: 'rgba(18, 218, 178, 1)' },
  ];

  const mentalStatesTestB = [
    { text: 'Deep Work', color: 'rgba(254, 90, 131, 1)' },
    { text: 'Creativity', color: 'rgba(254, 90, 131, 1)' },
    { text: 'Learning', color: 'rgba(254, 90, 131, 1)' },
    { text: 'Motivation', color: 'rgba(254, 90, 131, 1)' },
  ];

  const mentalStates = variant === 'B' ? mentalStatesTestB : mentalStatesTestA;
  const [containerStyle, setContainerStyle] = useState({
    '--color': mentalStates[0].color,
    '--width': '0px'  // Initial value, will be updated after render
  });
  const measureRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % mentalStates.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [mentalStates.length]);

    
  useEffect(() => {
    if (measureRef.current) {
      const currentStateElement = measureRef.current.querySelector(`span[data-index="${currentIndex}"]`);
      if (currentStateElement) {
        setContainerStyle({
          '--color': mentalStates[currentIndex].color,
          '--width': `${currentStateElement.offsetWidth}px`
        });
      }
    }
  }, [currentIndex, mentalStates]);

  return (
    <S.WordsWrapper>
      <>Music </>
      <S.NewLine>
        <br/>
      </S.NewLine>
      <>made for </>
      <S.NewLine>
        <br/>
      </S.NewLine>
      <S.WordsContainer ref={measureRef} style={containerStyle as any}>
        {mentalStates.map((mentalState, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'current' : index === (currentIndex + 1) % mentalStates.length ? 'next' : ''}
            data-color={mentalState.color}
            data-index={index}
          >
            {mentalState.text}
          </span>
        ))}
      </S.WordsContainer>
    </S.WordsWrapper>
  );
};
